import React, { useEffect, useState } from "react"
import { axiosApi } from "../utils/axiosInstance.js"
import { toast } from "react-toastify"
export default function useGetEnterpriseUsers() {
  const [loading, setLoading] = useState(true)
  const [enterpriseUsers, setEnterpriseUsers] = useState([])

  const getRoles = async () => {
    const rolesData = await axiosApi.get("/dashboard/roles")
    return rolesData.data
  }
  const getEnterpriseUsers = async () => {
    try {
      const userRoles = await getRoles()
      const response = await axiosApi.get("/dashboard/enterpriseUsers")
      if (response.status === 200) {
        const enterpriseUsersWithRoles = response.data.map((user) => {
          const roles = userRoles.filter((role) =>
            user.roles.includes(role._id)
          )
          return {
            ...user,
            roles,
          }
        })
        setEnterpriseUsers(enterpriseUsersWithRoles)
      }
    } catch (error) {
      const errorMessage =
        error.message === "Network Error"
          ? "Please check your internet connection"
          : error.response?.data || "Something went wrong"

      toast.error(errorMessage)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getEnterpriseUsers()
  }, [])
  return { loading, enterpriseUsers }
}
