import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { axiosApi } from "../utils/axiosInstance.js"
import Features from "../components/Form/Arrays/Features"
import propertyTypes from "../mocks/propertyTypes.json"
import FormInput from "../components/Form/Inputs/FormInput"
import useGetPropertyData from "../hooks/useGetPropertyData"
import ImageWithDeleteButton from "../components/Form/ImageWithDeleteButton"
import ImageSliderWithDeleteButton from "../components/Form/ImageSliderWithDeleteButton"
import { deleteVideo } from "../utils/ImageUtils"
import Loading from "../components/Loading"
export default function FormEditProperty() {
  const [characteristicsToUpdate, setCharacteristicsToUpdate] = useState([])
  const [usagesToUpdate, setUsagesToUpdate] = useState([])
  const [amenitiesToUpdate, setAmenitiesToUpdate] = useState([])
  const [commonZonesToUpdate, setCommonZonesToUpdate] = useState([])
  const [ofTheSectorToUpdate, setOfTheSectorToUpdate] = useState([])
  const [imageSliderToUpdate, setImageSliderToUpdate] = useState([])
  const [imageCardToUpdate, setImageCardToUpdate] = useState({
    url: "",
    publicId: "",
  })
  const [videoToUpdate, setVideoToUpdate] = useState({
    url: "",
    publicId: "",
  })
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const id = queryParams.get("id")
  const { loading, property } = useGetPropertyData(id)
  const { city, neighborhood, street, state } = property?.address || {}
  const {
    amenities,
    characteristics,
    commonZones,
    description,
    ofTheSector,
    propertyType,
    status,
    usage,
  } = property || {}
  const {
    antiquity,
    builtArea,
    cornerBack,
    privateArea,
    westView,
    eastView,
    amountAdmin,
    levels,
    floor,
    parking,
    price,
    pricePerMeter,
    stratus,
  } = property?.data || {}
  const {
    auxiliaryDiningRoom,
    americanStyleKitchen,
    americanStyleBar,
    bedroomHalls,
    bedrooms,
    bibliotecary,
    chimney,
    closet,
    clothingRoom,
    courtyardOfClothes,
    coveredParking,
    deposit,
    diningRoom,
    hall,
    integralKitchen,
    internalGarden,
    kitchen,
    laundryRoom,
    mainBathroom,
    marbledFloor,
    otherTypeOfFloor,
    roomBathroom,
    serviceRoom,
    serviceBathrooms,
    scortRoom,
    studyRoom,
    socialBathroom,
    stairs,
    terrace,
    tiledFloor,
    tvRoom,
    uncoveredParking,
    visitorParking,
    washerAndDryer,
    woodenFloor,
  } = property?.components || {}
  const { register, handleSubmit, setValue } = useForm({})
  const handleSubmitProperty = async (data) => {
    try {
      const formData = new FormData()
      const {
        auxiliaryDiningRoom,
        americanStyleKitchen,
        amountAdmin,
        americanStyleBar,
        antiquity,
        bedroomHalls,
        bedrooms,
        bibliotecary,
        builtArea,
        chimney,
        city,
        closet,
        clothingRoom,
        cornerBack,
        coveredParking,
        courtyardOfClothes,
        deposit,
        description,
        diningRoom,
        eastView,
        floor,
        hall,
        integralKitchen,
        internalGarden,
        kitchen,
        laundryRoom,
        levels,
        mainBathroom,
        marbledFloor,
        neighborhood,
        price,
        pricePerMeter,
        privateArea,
        propertyId,
        otherTypeOfFloor,
        roomBathroom,
        serviceRoom,
        serviceBathrooms,
        socialBathroom,
        stairs,
        state,
        studyRoom,
        stratus,
        scortRoom,
        street,
        tiledFloor,
        tvRoom,
        terrace,
        typeOfProperty,
        uncoveredParking,
        visitorParking,
        washerAndDryer,
        westView,
        woodenFloor,
        status,
      } = data
      formData.append("imageCard", data.imageCard[0])
      for (const image of data.imagesSlider) {
        formData.append("imagesSlider", image)
      }
      const property = {
        propertyType: typeOfProperty,
        address: {
          street,
          neighborhood,
          city,
          state,
        },
        amenities: amenitiesToUpdate,
        characteristics: characteristicsToUpdate,
        commonZones: commonZonesToUpdate,
        components: {
          auxiliaryDiningRoom: parseInt(auxiliaryDiningRoom),
          americanStyleKitchen: parseInt(americanStyleKitchen),
          americanStyleBar: parseInt(americanStyleBar),
          bedroomHalls: parseInt(bedroomHalls),
          bedrooms: parseInt(bedrooms),
          bibliotecary: parseInt(bibliotecary),
          chimney: parseInt(chimney),
          closet: parseInt(closet),
          clothingRoom: parseInt(clothingRoom),
          courtyardOfClothes: parseInt(courtyardOfClothes),
          coveredParking: parseInt(coveredParking),
          deposit: parseInt(deposit),
          diningRoom: parseInt(diningRoom),
          hall: parseInt(hall),
          integralKitchen: parseInt(integralKitchen),
          internalGarden: parseInt(internalGarden),
          kitchen: parseInt(kitchen),
          laundryRoom: parseInt(laundryRoom),
          mainBathroom: parseInt(mainBathroom),
          marbledFloor: parseInt(marbledFloor),
          otherTypeOfFloor,
          roomBathroom: parseInt(roomBathroom),
          serviceRoom: parseInt(serviceRoom),
          serviceBathrooms: parseInt(serviceBathrooms),
          socialBathroom: parseInt(socialBathroom),
          studyRoom: parseInt(studyRoom),
          stairs: parseInt(stairs),
          scortRoom: parseInt(scortRoom),
          tiledFloor: parseInt(tiledFloor),
          terrace: parseInt(terrace),
          tvRoom: parseInt(tvRoom),
          visitorParking: parseInt(visitorParking),
          uncoveredParking: parseInt(uncoveredParking),
          washerAndDryer: parseInt(washerAndDryer),
          woodenFloor: parseInt(woodenFloor),
        },
        data: {
          amountAdmin: parseFloat(amountAdmin),
          antiquity: parseInt(antiquity),
          builtArea: parseFloat(builtArea),
          cornerBack: cornerBack === "true" ? true : false,
          eastView: eastView === "true" ? true : false,
          floor: parseInt(floor),
          levels: parseInt(levels),
          price: parseInt(price),
          pricePerMeter: parseFloat(pricePerMeter),
          privateArea: parseFloat(privateArea),
          stratus: parseInt(stratus),
          westView: westView === "true" ? true : false,
        },
        description,
        media: {
          imageCard: imageCardToUpdate
            ? imageCardToUpdate
            : {
                url: "",
                publicId: "",
              },
          imageSlider: imageSliderToUpdate,
          video: videoToUpdate,
        },
        ofTheSector: ofTheSectorToUpdate,
        usage: usagesToUpdate,
        status,
      }
      formData.append("property", JSON.stringify(property))
      const response = await axiosApi.put(
        `/realState?propertyId=${propertyId}`,
        formData
      )
      toast.success(response.data)
    } catch (error) {
      const errorMessage =
        error.response === "Network Error"
          ? "Network error"
          : error.response.data
      toast.error(errorMessage)
    }
  }
  const setDefaultFormValues = () => {
    if (property) {
      setCharacteristicsToUpdate(characteristics)
      setAmenitiesToUpdate(amenities)
      setCommonZonesToUpdate(commonZones)
      setOfTheSectorToUpdate(ofTheSector)
      setUsagesToUpdate(usage)
      setImageSliderToUpdate(property.media.imageSlider)
      setImageCardToUpdate(property.media.imageCard)
      setVideoToUpdate(property.media.video)
      const defaultValues = {
        typeOfProperty: propertyType,
        propertyId: property._id,
        auxiliaryDiningRoom,
        americanStyleKitchen,
        americanStyleBar,
        description,
        bedrooms,
        bedroomHalls,
        bibliotecary,
        deposit,
        closet,
        cornerBack,
        clothingRoom,
        coveredParking,
        courtyardOfClothes,
        diningRoom,
        hall,
        integralKitchen,
        internalGarden,
        kitchen,
        laundryRoom,
        mainBathroom,
        marbledFloor,
        roomBathroom,
        chimney,
        stratus,
        floor,
        terrace,
        builtArea,
        privateArea,
        levels,
        eastView,
        westView,
        pricePerMeter,
        price,
        parking,
        antiquity,
        amountAdmin,
        street,
        otherTypeOfFloor,
        serviceRoom,
        socialBathroom,
        stairs,
        visitorParking,
        serviceBathrooms,
        scortRoom,
        studyRoom,
        tiledFloor,
        tvRoom,
        uncoveredParking,
        washerAndDryer,
        woodenFloor,
        neighborhood,
        city,
        status,
        state,
      }
      for (const key in defaultValues) {
        setValue(key, defaultValues[key])
      }
    }
  }
  useEffect(() => {
    setDefaultFormValues()
  }, [property, setValue])

  if (loading) return <Loading />

  return (
    <main className="mx-10 py-5">
      <h1 className=" mt-12 text-center text-3xl font-bold md:text-left">
        EDITAR INMUEBLE
      </h1>
      <Features
        label="Características"
        featuresToUpdate={characteristicsToUpdate}
        setFeaturesToUpdate={setCharacteristicsToUpdate}
      />
      <Features
        label="Tipo de negocio"
        featuresToUpdate={usagesToUpdate}
        setFeaturesToUpdate={setUsagesToUpdate}
      />
      <Features
        label="Amenidades"
        featuresToUpdate={amenitiesToUpdate}
        setFeaturesToUpdate={setAmenitiesToUpdate}
      />
      <Features
        label="Zonas Comunes"
        featuresToUpdate={commonZonesToUpdate}
        setFeaturesToUpdate={setCommonZonesToUpdate}
      />
      <Features
        label="Del Sector"
        featuresToUpdate={ofTheSectorToUpdate}
        setFeaturesToUpdate={setOfTheSectorToUpdate}
      />
      <form
        className="space-y-10 "
        onSubmit={handleSubmit(handleSubmitProperty)}
      >
        <div className="grid gap-5 my-10">
          {/*ImageCard*/}
          <div className="flex flex-col gap-2.5">
            <h2 className="font-medium text-lg">Imagen Fachada</h2>
            <input type="file" accept="image/*" {...register("imageCard")} />
            {property && imageCardToUpdate.url !== "" ? (
              <ImageWithDeleteButton
                url={imageCardToUpdate.url}
                propertyId={property._id}
                imagePublicId={imageCardToUpdate.publicId}
                setImageCardToUpdate={setImageCardToUpdate}
              />
            ) : (
              <p>No image Card Available</p>
            )}
          </div>
          {/*Image Slider*/}
          <div className="flex flex-col gap-2.5">
            <h2 className="font-medium text-lg">Imagenes Slider</h2>
            <input
              type="file"
              multiple
              accept="image/*"
              {...register("imagesSlider")}
            />
            <div className="grid xl:grid-cols-3 gap-x-4 gap-y-2 overflow-x-hidden">
              {property &&
                imageSliderToUpdate?.length >= 1 &&
                imageSliderToUpdate.map((image, index) => (
                  <div key={index}>
                    <ImageSliderWithDeleteButton
                      imagePublicId={image.publicId}
                      index={index}
                      propertyId={property._id}
                      setImageSliderToUpdate={setImageSliderToUpdate}
                      url={image.url}
                    />
                  </div>
                ))}
            </div>
          </div>
          {/*Video*/}
          <div className="flex flex-col gap-2.5">
            <h2 className="font-medium text-lg">Video</h2>
            <input
              type="file"
              multiple
              accept="video/*"
              {...register("video")}
            />
            <div className="grid md:grid-cols-2">
              {property && videoToUpdate.url !== "" ? (
                <div className="relative xl:w-[340px]">
                  <video className="" controls>
                    <source src={videoToUpdate.url} type="video/mp4" />
                  </video>
                  <button
                    className="absolute right-1 top-1"
                    type="button"
                    onClick={(e) =>
                      deleteVideo({
                        e,
                        propertyId: property._id,
                        publicId: videoToUpdate.publicId,
                        setVideoToUpdate,
                      })
                    }
                  >
                    <img
                      className="hover:w-7 hover:h-7"
                      src="/icons/closeCircle.svg"
                      alt="Close Icon"
                      width={24}
                      height={24}
                    />
                  </button>
                </div>
              ) : (
                <p>No video Available</p>
              )}
            </div>
          </div>
        </div>
        <div className="grid xl:grid-cols-2 gap-2.5 ">
          <div className="flex flex-col gap-2">
            <h2 className="font-normal text-lg">Tipo de propiedad</h2>
            <select
              id="typeOfProperty"
              className="xl:w-64 xl:h-11 flex gap-2.5 px-2 py-2.5 border rounded border-grayBorderNavigationSidebar"
              {...register("typeOfProperty", { required: true })}
            >
              {propertyTypes.map((type) => (
                <option key={type.id} value={type.value}>
                  {type.value}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2.5">
            <h2 className="font-medium text-lg">Descripción</h2>
            <textarea
              className="px-4 py-2 border rounded border-grayTextConsultory outline-none"
              {...register("description")}
            ></textarea>
          </div>
        </div>
        <div className="grid gap-5 xl:grid-cols-2">
          {/*Property Data*/}
          <div className="flex flex-col items-center gap-2.5">
            <h2 className="font-normal text-lg">Datos del inmueble</h2>
            <div className="grid w-full xl:grid-cols-2 gap-10">
              <FormInput
                label="stratus"
                name="Estrato"
                type="number"
                register={register}
              />

              <FormInput
                label="antiquity"
                name="Antiguedad"
                type="number"
                register={register}
                step="any"
              />
              <FormInput
                label="pricePerMeter"
                name="Precio por metro cuadrado"
                type="number"
                register={register}
                required={true}
                step="any"
              />

              <FormInput
                label="floor"
                name="Piso"
                type="number"
                register={register}
                required={true}
              />
              <FormInput
                label="builtArea"
                name="Area construida"
                type="number"
                register={register}
                required={true}
                step="any"
              />
              <FormInput
                label="privateArea"
                name={"Area privada"}
                type="number"
                register={register}
                required={true}
                step="any"
              />
              <FormInput
                label="price"
                name={"Precio de venta"}
                type="number"
                register={register}
                required={true}
              />
              <FormInput
                label="levels"
                name={"Niveles"}
                type="number"
                register={register}
                required={true}
              />
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm" htmlFor="cornerBack">
                  Esquinero
                </label>
                <select
                  id="cornerBack"
                  className="xl:w-64 xl:h-11 flex gap-2.5 px-2 py-2.5 border rounded border-grayBorderNavigationSidebar"
                  {...register("cornerBack")}
                >
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm" htmlFor="eastView">
                  Vista al oriente
                </label>
                <select
                  id="eastView"
                  className="xl:w-64 xl:h-11 flex gap-2.5 px-2 py-2.5 border rounded border-grayBorderNavigationSidebar"
                  {...register("eastView")}
                >
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm" htmlFor="westView">
                  Vista al occidente
                </label>
                <select
                  id="westView"
                  className="xl:w-64 xl:h-11 flex gap-2.5 px-2 py-2.5 border rounded border-grayBorderNavigationSidebar"
                  {...register("westView")}
                >
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </select>
              </div>

              <FormInput
                label="amountAdmin"
                name={"Cuota de administración"}
                type="number"
                register={register}
                required={false}
                step="any"
              />
            </div>
          </div>
          {/*Address*/}
          <div className="flex flex-col items-center gap-2.5">
            <h2 className="font-normal text-lg mt-5">Dirección</h2>
            <div className="flex flex-col w-full gap-5 xl:items-center">
              <FormInput
                label="street"
                name={"Calle"}
                type="text"
                register={register}
                required={true}
              />
              <FormInput
                label="neighborhood"
                name={"Barrio"}
                type="text"
                register={register}
                required={true}
              />
              <FormInput
                label="city"
                name={"Ciudad"}
                type="text"
                register={register}
                required={true}
              />
              <FormInput
                label="state"
                name={"Departamento"}
                type="text"
                register={register}
                required={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <label className="text-gray-500 text-Fsm" htmlFor="status">
            Disponibilidad
          </label>
          <select
            id="status"
            className="xl:w-64 xl:h-11 flex gap-2.5 px-2 py-2.5 border rounded border-grayBorderNavigationSidebar"
            {...register("status")}
          >
            <option value="available">Disponible</option>
            <option value="Not available">No disponible</option>
          </select>
        </div>
        {/*Components*/}
        <div className="flex flex-col items-center gap-2.5">
          <h2 className="font-normal text-lg">Componentes del inmueble</h2>
          <div className="grid w-full xl:grid-cols-4 gap-10">
            <FormInput
              label="auxiliaryDiningRoom"
              name="Comedor auxiliar"
              type="number"
              register={register}
            />
            <FormInput
              label="americanStyleKitchen"
              name="Cocina tipo americano"
              type="number"
              register={register}
            />
            <FormInput
              label="americanStyleBar"
              name="Barra estilo americano"
              type="number"
              register={register}
            />
            <FormInput
              label="bedrooms"
              name="Habitaciones"
              type="number"
              register={register}
            />
            <FormInput
              label="bedroomHalls"
              name="Hall de alcobas"
              type="number"
              register={register}
            />
            <FormInput
              label="bibliotecary"
              name="Biblioteca"
              type="number"
              register={register}
            />
            <FormInput
              label="closet"
              name="Closet"
              type="number"
              register={register}
            />
            <FormInput
              label="clothingRoom"
              name="Clothing Room"
              type="number"
              register={register}
            />
            <FormInput
              label="chimney"
              name="Chimenea"
              type="number"
              register={register}
            />
            <FormInput
              label="coveredParking"
              name="Parqueadero cubierto"
              type="number"
              register={register}
            />
            <FormInput
              label="courtyardOfClothes"
              name="Patio de ropas"
              type="number"
              register={register}
            />
            <FormInput
              label="deposit"
              name="Depósito"
              type="number"
              register={register}
            />
            <FormInput
              label="hall"
              name="Hall"
              type="number"
              register={register}
            />
            <FormInput
              label="internalGarden"
              name="Jardín interno"
              type="number"
              register={register}
            />
            <FormInput
              label="integralKitchen"
              name="Cocina integral"
              type="number"
              register={register}
            />
            <FormInput
              label="kitchen"
              name="Cocina"
              type="number"
              register={register}
            />
            <FormInput
              label="laundryRoom"
              name="Cuarto de lavandería"
              type="number"
              register={register}
            />
            <FormInput
              label="mainBathroom"
              name="Baño principal"
              type="number"
              register={register}
            />
            <FormInput
              label="marbledFloor"
              name="Piso en mármol"
              type="number"
              register={register}
            />
            <FormInput
              label="otherTypeOfFloor"
              name="Otro tipo de piso"
              type="text"
              register={register}
            />
            <FormInput
              label="uncoveredParking"
              name="Parqueadero descubierto"
              type="number"
              register={register}
            />
            <FormInput
              label="roomBathroom"
              name="Baño de habitaciónes"
              type="number"
              register={register}
            />
            <FormInput
              label="terrace"
              name="Terraza"
              type="number"
              register={register}
            />
            <FormInput
              label="tvRoom"
              name="Sala de tv"
              type="number"
              register={register}
            />
            <FormInput
              label="tiledFloor"
              name="Piso en baldosa"
              type="number"
              register={register}
            />
            <FormInput
              label="scortRoom"
              name="Sala de escoltas"
              type="number"
              register={register}
            />
            <FormInput
              label="serviceRoom"
              name="Cuarto de servicio"
              type="number"
              register={register}
            />
            <FormInput
              label="serviceBathrooms"
              name="Baños de servicio"
              type="number"
              register={register}
            />
            <FormInput
              label="stairs"
              name="Escaleras"
              type="number"
              register={register}
            />
            <FormInput
              label="studyRoom"
              name="Estudio"
              type="number"
              register={register}
            />
            <FormInput
              label="socialBathroom"
              name="Baño social"
              type="number"
              register={register}
            />
            <FormInput
              label="visitorParking"
              name="Parqueadero para visitantes"
              type="number"
              register={register}
            />
            <FormInput
              label="washerAndDryer"
              name="Lavadora y secadora"
              type="number"
              register={register}
            />
            <FormInput
              label="woodenFloor"
              name="Pisos en madera"
              type="number"
              register={register}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className="px-4 w-full h-11 mb-10 lg:mx-2 bg-blueLogo rounded-[6px] text-white hover:bg-opacity-90"
            type="submit"
          >
            Update
          </button>
        </div>
      </form>
    </main>
  )
}
