import { useState } from "react"
import FeatureItem from "./FeatureItem"
export default function Features({
  label,
  featuresToUpdate,
  setFeaturesToUpdate,
}) {
  const [feature, setFeature] = useState("")
  const handleFeatureChange = (e) => {
    setFeature(e.target.value)
  }
  const handleAddFeature = (feature) => {
    if (feature.trim() !== "") {
      if (!featuresToUpdate.includes(feature)) {
        setFeaturesToUpdate([...featuresToUpdate, feature])
        setFeature("")
      }
    }
  }
  const handleDeleteFeature = (feature) => {
    setFeaturesToUpdate(featuresToUpdate.filter((el) => el !== feature))
  }
  return (
    <div className="flex flex-col gap-4 mt-10">
      <h2 className="font-medium text-lg">{label}</h2>
      <div className="grid gap-2.5 xl:flex ">
        <input
          className="px-4 h-12 border rounded border-grayBorderNavigationSidebar outline-none hover:bg-blue-100 transition-all ease-in"
          type="text"
          placeholder="New Feature"
          value={feature}
          onChange={(e) => handleFeatureChange(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleAddFeature(feature)
            }
          }}
        />
        <button
          className=" px-4 h-11 lg:mx-2 py-1 bg-blueLogo rounded-[6px] text-white hover:bg-opacity-90"
          data-testid="add"
          onClick={() => handleAddFeature(feature)}
        >
          Add
        </button>
      </div>
      <div className="grid xl:grid-cols-4 gap-3">
        {featuresToUpdate?.map((feature, index) => (
          <div key={index}>
            <FeatureItem
              feature={feature}
              handleDeleteFeature={handleDeleteFeature}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
