/** @format */

import React from "react"

export default function SearchInput({
  search,
  handleSearchChange,
  placeHolder,
}) {
  return (
    <div className="flex px-2 h-14 rounded border border-grayText bg-grayBgDropdown lg:px-0 lg:w-64 lg:h-16  ">
      <img
        className="ml-2"
        src="/icons/search.svg"
        alt="Search icon"
        width={24}
        height={24}
      />
      <input
        className="px-2 outline-none bg-transparent text-grayText"
        placeholder={placeHolder}
        value={search}
        onChange={(e) => handleSearchChange(e)}
      />
    </div>
  )
}
