/** @format */
import { deleteImageCard } from "../../utils/ImageUtils"
export default function ImageWithDeleteButton({
  imagePublicId,
  propertyId,
  setImageCardToUpdate,
  url,
}) {
  return (
    <div className="relative px-1 xl:w-[340px] ">
      <img className="rounded" src={url} alt="Building Facade" />
      <button
        className="absolute right-1 top-1"
        formNoValidate={true}
        onClick={(e) =>
          deleteImageCard({
            e,
            propertyId: propertyId,
            publicId: imagePublicId,
            setImageCardToUpdate: setImageCardToUpdate, //
          })
        }
      >
        <img
          className="hover:w-7 hover:h-7"
          src="/icons/closeCircle.svg"
          alt="Close Icon"
          width={24}
          height={24}
        />
      </button>
    </div>
  )
}
