import { useState } from "react"
import Dropdown from "../components/Dropdown"
import SearchInput from "../components/SearchInput"
import useGetEnterpriseUsers from "../hooks/useGetEnterpriseUsers"
import Select from "../components/Select"
import { useDeleteEnterpriseUser } from "../hooks/useDeleteEnterpriseUser"
import Loading from "../components/Loading"
import { useNavigate } from "react-router-dom"
import ReactPaginate from "react-paginate"
export default function EnterpriseUsers() {
  const navigate = useNavigate()
  const { deleteEnterpriseUser } = useDeleteEnterpriseUser()
  const { loading, enterpriseUsers } = useGetEnterpriseUsers()
  const [selectedRole, setSelectedRole] = useState("")
  const [search, setSearch] = useState("")
  const [sortOrder, setSortOrder] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 5
  const pageCount = Math.ceil(enterpriseUsers.length / itemsPerPage)
  const startIndex = currentPage * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const handleOnchageCheckboxRoles = (role) => {
    setSelectedRole((prevSelectedRole) =>
      prevSelectedRole.includes(role)
        ? prevSelectedRole.filter((prevRole) => prevRole !== role)
        : [...prevSelectedRole, role]
    )
  }
  const handleOnchageSearch = (e) => {
    setSearch(e.target.value)
  }
  const handleOnchangeSortOrder = (e) => {
    setSortOrder(e.target.value)
  }
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  }
  const filteredUserData = enterpriseUsers.filter((user) => {
    const roleMatched =
      selectedRole.length === 0 ||
      selectedRole.includes(user.roles[0].name.toLowerCase())

    const searchMatched =
      search === "" ||
      user.username.toLowerCase().includes(search.toLowerCase())

    return roleMatched && searchMatched
  })
  const sortedUserData = filteredUserData
    .slice(startIndex, endIndex)
    .sort((a, b) => {
      const valueA = a.username
      const valueB = b.username
      return sortOrder === "ascendent"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA)
    })

  if (loading) return <Loading />
  return (
    <main className="flex-1">
      <div className="text-center p-5">
        <h1 className="text-center text-blueLogo text-3xl font-bold my-3 xl:text-left">
          USUARIOS
        </h1>
        <div className="flex flex-col gap-y-10 w-full my-5 items-center xl:flex-row xl:justify-between ">
          <Dropdown
            values={["admin", "moderator"]}
            handleCheckboxChange={handleOnchageCheckboxRoles}
            selectedValue={selectedRole}
            label="Selecciona por Tipo de Rol"
          />
          <div className="flex flex-col gap-2.5">
            <h2 className="hidden font-medium text-sm">Buscar por nombre</h2>
            <div className="flex flex-col items-center gap-2">
              <SearchInput
                handleSearchChange={handleOnchageSearch}
                placeHolder="Alexander"
                search={search}
              />
              <Select
                sortOrder={sortOrder}
                handleOnchangeSortOrder={handleOnchangeSortOrder}
              />
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="flex flex-col border border-gray-300 rounded">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 py-2">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden"></div>
              <table className="min-w-full text-left text-sm font-light">
                <thead className="text-grayBgSlider font-bold border-b border-grayBorderNavigationSidebar">
                  <tr className="text-base text-grayBgSlider font-bold">
                    <th scope="col" className="px-6 py-3">
                      Nombre de usuario
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Rol
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Opciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedUserData.map((user, index) => (
                    <tr className="border-b" key={index}>
                      <td className="whitespace-nowrap px-6 py-4">
                        {user.username}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        {user.roles[0].name}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4">
                        <button onClick={() => deleteEnterpriseUser(user._id)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            className="w-6 h-6"
                            viewBox="0 0 512 512"
                            fill="#d32f12"
                          >
                            <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                          </svg>
                        </button>
                        <button
                          className="ml-2"
                          onClick={() =>
                            navigate(
                              `/dashboard/enterpriseUsers/formEditUser?id=${user._id}`
                            )
                          }
                        >
                          <img src="/icons/edit.svg" alt="Edit icon" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Pagination for table*/}
        <ReactPaginate
          className="flex my-4"
          previousLabel={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          }
          nextLabel={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          }
          pageCount={pageCount}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName={
            "inline-block mx-1 bg-blueLogo text-white px-2 rounded"
          }
        />
        <a
          className="flex w-36 h-12 justify-center items-center rounded bg-blueLogo text-white hover:bg-opacity-80 transition-opacity duration-300ms ease-in"
          href="/dashboard/enterpriseUsers/formNewUser"
        >
          Add
        </a>
      </div>
    </main>
  )
}
