/** @format */

import { axiosApi } from "../utils/axiosInstance.js"
import { confirmAlert } from "react-confirm-alert"
import { toast } from "react-toastify"
import "react-confirm-alert/src/react-confirm-alert.css"
export default function useDeleteProperty() {
  const deleteProperty = async (id) => {
    confirmAlert({
      title: "Delete Property?",
      message: "Are you sure you want to delete the property",
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              const response = await axiosApi.delete(
                `/dashboard/realState?id=${id}`
              )
              toast.success(response.data)
            } catch (error) {
              console.error(error)
              toast.error(error.response?.data || "Something went wrong")
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            // User clicked No or closed the dialog
          },
        },
      ],
    })
  }
  return { deleteProperty }
}
