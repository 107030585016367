/** @format */

export default function FeatureItem({ feature, handleDeleteFeature }) {
  return (
    <div
      className="relative p-4 border border-blueLogo bg-opacity-95 rounded"
      data-testid="feature"
    >
      {feature}
      <button onClick={() => handleDeleteFeature(feature)} data-testid="delete">
        <img
          className="absolute right-1 top-1 hover:w-7 hover:h-7"
          src="/icons/closeCircle.svg"
          alt="Close icon"
          width={24}
          height={24}
        />
      </button>
    </div>
  )
}
