/** @format */
import { useState } from "react"
import useDeleteProperty from "../hooks/useDeleteProperty"
import useGetRealStateData from "../hooks/useGetRealStateData"
import ReactPaginate from "react-paginate"
import { useNavigate } from "react-router-dom"
import Dropdown from "../components/Dropdown"
import Loading from "../components/Loading"
import SearchInput from "../components/SearchInput"
export default function RealState() {
  const navigate = useNavigate()
  const { deleteProperty } = useDeleteProperty()
  const [search, setSearch] = useState("")
  const [sortOrder, setSortOrder] = useState("")
  const { propertyData, loading } = useGetRealStateData()
  const [selectedUsage, setSelectedUsages] = useState([])
  const usages = ["venta", "arriendo"]
  const typesOfProperty = ["apartamento", "casa", "lote", "local"]
  const [selectedTypeOfProperty, setSelectedTypeOfProperty] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  //Logic for pagination
  const itemsPerPage = 5
  const pageCount = Math.ceil(propertyData.length / itemsPerPage)
  const startIndex = currentPage * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const handleOnchageCheckboxUsages = (usage) => {
    setSelectedUsages((prevSelectedUsage) =>
      prevSelectedUsage.includes(usage)
        ? prevSelectedUsage.filter(
            (prevSelectedUsage) => prevSelectedUsage !== usage
          )
        : [...prevSelectedUsage, usage]
    )
  }
  const handleOnchangeCheckboxTypesOfProperty = (type) => {
    setSelectedTypeOfProperty((prevSelectedType) =>
      prevSelectedType.includes(type)
        ? prevSelectedType.filter((selectedType) => selectedType !== type)
        : [...prevSelectedType, type]
    )
  }
  const handleOnchageSearch = (e) => {
    setSearch(e.target.value)
  }
  const handleOnchangeSortOrder = (e) => {
    setSortOrder(e.target.value)
  }
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  }
  //Logic for filter the data by usage, type of property and search
  const filteredPropertyData = propertyData.filter((property) => {
    const typeMatched =
      selectedTypeOfProperty.length === 0 ||
      selectedTypeOfProperty.includes(property.propertyType.toLowerCase())

    const searchMatched =
      search === "" ||
      property.address.city.toLowerCase().includes(search.toLowerCase())

    const usageMatched =
      selectedUsage.length === 0 ||
      property.usage.some((usage) =>
        selectedUsage.includes(usage.toLowerCase())
      )

    return typeMatched && searchMatched && usageMatched
  })

  //Logic for sort the data by ascendent or descendent order
  const sortedFilteredData = filteredPropertyData
    .slice(startIndex, endIndex)
    .sort((a, b) => {
      const valueA = a.address.city
      const valueB = b.address.city
      return sortOrder === "ascendent"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA)
    })

  if (loading) {
    return <Loading />
  }
  return (
    <main className="flex-1 ">
      <div className="text-center p-5">
        <h1 className="text-center text-blueLogo text-3xl font-bold my-3 xl:text-left">
          INMUEBLES
        </h1>
        <div className="flex flex-col gap-y-10 w-full my-5 items-center xl:flex-row xl:justify-between ">
          <div className="grid grid-cols-2 gap-x-10">
            <Dropdown
              values={typesOfProperty}
              handleCheckboxChange={handleOnchangeCheckboxTypesOfProperty}
              selectedValue={selectedTypeOfProperty}
              label="Selecciona por Tipo de Inmueble"
            />
            <Dropdown
              handleCheckboxChange={handleOnchageCheckboxUsages}
              values={usages}
              selectedValue={selectedUsage}
              label="Selecciona por tipo de Uso"
            />
          </div>
          <div className="flex flex-col gap-2.5">
            <h2 className="hidden font-medium text-sm lg:flex">
              Buscar por Ciudad
            </h2>
            <div className="flex flex-col items-center gap-2">
              <SearchInput
                handleSearchChange={handleOnchageSearch}
                placeHolder={"Neiva"}
                search={search}
              />
              <select
                className="outline-none cursor-pointer"
                value={sortOrder}
                onChange={(e) => handleOnchangeSortOrder(e)}
                placeholder="Sort by"
              >
                <option value="" disabled>
                  Ordena por
                </option>
                <option value="ascendent">A/z</option>
                <option value="descendent">Z/a</option>
              </select>
            </div>
          </div>
        </div>
        {/* Table  */}
        <div className="flex flex-col border border-gray-300 rounded">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 py-2">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden ">
                <table className="min-w-full text-left text-sm font-light">
                  <thead className="text-grayBgSlider border-b font-medium text-base xl:text-lg">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Tipo de inmueble
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Tipo de de negocio
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Dirección
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Ciudad
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Departamento
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Opciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedFilteredData.map((property, index) => (
                      <tr className="border-b" key={index}>
                        <td className="whitespace-nowrap px-6 py-4">
                          {property.propertyType}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {property.usage.map((usage, index) => (
                            <span key={index} className="mx-1">
                              {usage}
                            </span>
                          ))}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {property.status === "available" ? (
                            <div className="inline-flex items-center gap-2 ">
                              <svg
                                viewBox="0 0 12 12"
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <circle
                                    cx="6"
                                    cy="6"
                                    r="6"
                                    fill="#4dff00"
                                  ></circle>{" "}
                                </g>
                              </svg>
                              <span>Disponible</span>
                            </div>
                          ) : (
                            <div className="inline-flex items-center gap-2">
                              <svg
                                viewBox="0 0 12 12"
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="#000000"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <circle
                                    cx="6"
                                    cy="6"
                                    r="6"
                                    fill="#e00b0b"
                                  ></circle>{" "}
                                </g>
                              </svg>
                              <span>No disponible</span>
                            </div>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {property.address.street}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {property.address.city}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {property.address.state}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <button onClick={() => deleteProperty(property._id)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              className="w-6 h-6"
                              viewBox="0 0 512 512"
                              fill="#d32f12"
                            >
                              <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                            </svg>
                          </button>
                          <button
                            className="ml-2"
                            type="button"
                            onClick={() =>
                              navigate(
                                `/dashboard/realState/formEditProperty?id=${property._id}`
                              )
                            }
                          >
                            <img src="/icons/edit.svg" alt="Edit icon" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Pagination */}
        <ReactPaginate
          className="flex my-4"
          previousLabel={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          }
          nextLabel={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          }
          pageCount={pageCount}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName={
            "inline-block mx-1 bg-blueLogo text-white px-2 rounded"
          }
        />
        <a
          className="flex w-36 h-12 justify-center items-center rounded bg-blueLogo text-white hover:bg-opacity-80 transition-opacity duration-300ms ease-in"
          href="/dashboard/realState/formNewProperty"
        >
          Add
        </a>
      </div>
    </main>
  )
}
