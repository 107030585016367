import axios from "axios"
export const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
})

//Interceptor to refresh access token if expired
axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      error.config._retry = true
      //If the error is due to invalid credentials, reject the promise
      if (error.response.data === "Invalid credentials") {
        return Promise.reject(error)
      }
      try {
        await axiosApi.post("/auth/refreshToken")
        return axiosApi(error.config)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
)
