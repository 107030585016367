/** @format */

import React, { useState } from "react"

export default function Dropdown({
  handleCheckboxChange,
  values,
  selectedValue,
  label,
}) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <div className="flex flex-col xl:gap-2.5">
        <h2 className="hidden font-medium lg:flex">{label}</h2>
        <div className="relative">
          <button
            className="flex w-36 h-14 justify-between items-center px-4 rounded border border-grayText text-grayText bg-grayBgDropdown xl:w-64 xl:h-16"
            onClick={() => setIsOpen(!isOpen)}
          >
            Selecciona
            <img
              className={`${isOpen ? "transform rotate-90" : "-rotate-90"}`}
              src="/icons/caret.svg"
              alt="caret icon"
            />
          </button>
          <div className="absolute w-full bg-grayBgDropdown cursor-pointer">
            {isOpen &&
              values.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center my-4 gap-2 z-20 border-b border-grayBorderNavigationSidebar"
                >
                  <input
                    type="checkbox"
                    id={item}
                    value={item}
                    checked={selectedValue.includes(item)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <label htmlFor={item}>{item}</label>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
