import { axiosApi } from "./axiosInstance.js"
export const login = async (username, password) => {
  return await axiosApi.post("/auth/login", {
    username,
    password,
  })
}
export const logout = async () => {
  return axiosApi.post("/auth/logout")
}
