import React from "react";

export default function Select({ sortOrder, handleOnchangeSortOrder }) {
  return (
    <div>
      <select
        className="outline-none cursor-pointer"
        value={sortOrder}
        onChange={(e) => handleOnchangeSortOrder(e)}
        placeholder="Sort by"
      >
        <option value="" disabled>
          Ordena por
        </option>
        <option value="ascendent">A/z</option>
        <option value="descendent">Z/a</option>
      </select>
    </div>
  );
}
