/** @format */
import { toast } from "react-toastify"
import { axiosApi } from "../utils/axiosInstance"
import { useEffect, useState } from "react"
export default function useGetPropertyData(id) {
  const [loading, setLoading] = useState(true)
  const [property, setProperty] = useState(false)
  useEffect(() => {
    const getPropertyDataById = async () => {
      try {
        const response = await axiosApi.get(
          `/realState/property/propertyDetails?id=${id}`
        )
        if (response.status === 200) setProperty(response.data)
      } catch (error) {
        console.error(error)
        const errorMessage =
          error.message === "Network Error"
            ? "Please check your internet connection"
            : error.response?.data || "Something went wrong"
        toast.error(errorMessage)
      } finally {
        setLoading(false)
      }
    }
    getPropertyDataById()
    setLoading(false)
  }, [id])

  return { loading, property }
}
