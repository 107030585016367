import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"
import useGetEnterpriseUserData from "../hooks/useGetEnterpriseUserData"
import Loading from "../components/Loading"
import { axiosApi } from "../utils/axiosInstance.js"
import { toast } from "react-toastify"
import FormInput from "../components/Form/Inputs/FormInput"
import { useNavigate } from "react-router-dom"
export default function FormEditEnterpriseUsers() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const id = queryParams.get("id")
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const { userData, loading } = useGetEnterpriseUserData(id)
  const navigate = useNavigate()
  const handleEditUser = async (data) => {
    try {
      const response = await axiosApi.put(
        `/dashboard/enterpriseUsers?id=${id}`,
        data
      )
      if (response.status === 200) {
        navigate("/dashboard/enterpriseUsers")
        toast.success("Usuario editado correctamente")
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data)
    }
  }
  useEffect(() => {
    if (userData.roles) {
      setValue("username", userData.username)
      setValue("password", userData.password)
      setValue("roleName", userData.roles[0].name)
    }
  }, [userData, setValue])

  if (loading) {
    return <Loading />
  }

  return (
    <div className="mx-10 py-5">
      <h1 className="mt-12 text-center text-3xl font-bold md:text-left">
        EDITAR USUARIO
      </h1>
      <form className="space-y-10" onSubmit={handleSubmit(handleEditUser)}>
        <div className="flex flex-col mt-5 w-full xl:justify-between gap-3">
          <FormInput
            name="Nombre de usuario"
            label="username"
            type="text"
            required={false}
            errors={errors}
            register={register}
          />
          <FormInput
            name="Contraseña"
            label="password"
            type="text"
            required={false}
            errors={errors}
            register={register}
          />

          <div className="flex flex-col gap-2">
            <label className="text-gray-500">Role</label>
            <select
              className="flex gap-2.5 px-2 h-12 border rounded border-grayBorderNavigationSidebar outline-none xl:w-64 xl:h-11"
              {...register("roleName")}
            >
              <option value="admin">Administrador</option>
              <option value="moderator">Moderador</option>
            </select>
          </div>
        </div>
        <button
          className="flex w-36 h-12 justify-center items-center rounded bg-blueLogo text-white hover:bg-opacity-80 transition-opacity duration-300ms ease-in"
          type="submit"
        >
          Edit
        </button>
      </form>
    </div>
  )
}
