/** @format */

import { axiosApi } from "./axiosInstance"
import { toast } from "react-toastify"
export const deleteImageCard = async ({
  e,
  propertyId,
  publicId,
  setImageCardToUpdate,
}) => {
  try {
    e.preventDefault()
    const response = await axiosApi.delete(
      `cloudinary/deleteImageCard?publicId=${publicId}&propertyID=${propertyId}`
    )
    setImageCardToUpdate({ url: "", publicId: "" })
    toast.success(response.data)
  } catch (error) {
    toast.error("Error deleting image card")
  }
}
export const deleteImageSlider = async ({
  e,
  propertyId,
  publicId,
  setImageSliderToUpdate,
}) => {
  try {
    e.preventDefault()
    const response = await axiosApi.delete(
      `/cloudinary/deleteImageSlider?publicId=${publicId}&propertyID=${propertyId}`
    )
    setImageSliderToUpdate((prevImages) =>
      prevImages.filter((image) => image.publicId !== publicId)
    )
    toast.success(response.data)
  } catch (error) {
    toast.error(error.message)
  }
}
export const deleteVideo = async ({
  e,
  propertyId,
  publicId,
  setVideoToUpdate,
}) => {
  try {
    e.preventDefault()
    const response = await axiosApi.delete(
      `/cloudinary/deleteVideo?publicId=${publicId}&propertyID=${propertyId}`
    )
    setVideoToUpdate({ url: "", publicId: "" })
    toast.success(response.data)
  } catch (error) {
    toast.error(error.message)
  }
}
