import { useState, useEffect } from "react"
import { axiosApi } from "../utils/axiosInstance.js"
import { toast } from "react-toastify"
export default function useGetRealStateData() {
  const [propertyData, setPropertyData] = useState([])
  const [loading, setLoading] = useState(true)
  const getDataOfProperty = async () => {
    try {
      const response = await axiosApi.get("/RealState")
      if (response.status === 200) setPropertyData(response.data)
    } catch (error) {
      const errorMessage =
        error.message === "Network Error"
          ? "Please check your internet connection"
          : error.response?.data || "Something went wrong"
      return toast.error(errorMessage)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getDataOfProperty()
  }, [])

  return { propertyData, loading }
}
