/** @format */
/** @format */
import { useState } from "react"
import { logout } from "../utils/Auth"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useAuth } from "../context/auth"
export default function Navbar() {
  const links = [
    {
      name: "Usuarios",
      url: "/dashboard/enterpriseUsers",
      icon: (
        <svg
          className="w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 640 512"
        >
          <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
        </svg>
      ),
    },
    {
      name: "Inmobiliaria",
      url: "/dashboard/realState",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 576 512"
        >
          <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
        </svg>
      ),
    },
  ]
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false)
  const [isMenuDesktopOpen, setIsMenuDesktopOpen] = useState(false)
  const { setIsAuthenticated } = useAuth()
  const navigate = useNavigate()
  const handleLogout = async () => {
    try {
      await logout()
      window.localStorage.removeItem("MY_AUTH_APP", false)
      await setIsAuthenticated(false)
      toast.success("Log out successfully")
      navigate("/")
    } catch (error) {
      console.log(error)
      toast.error(error.response?.data || "Something went wrong")
    }
  }
  return (
    <nav className="relative shadow-xl">
      {/* Mobile Menu */}
      <div className="flex h-16 justify-between px-10 items-center md:hidden shadow-lg">
        <button
          type="button"
          onClick={() => setIsMenuMobileOpen(!isMenuMobileOpen)}
        >
          <svg
            className="w-6 h-6 text-black cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={
                isMenuMobileOpen
                  ? "M6 18L18 6M6 6l12 12"
                  : "M4 6h16M4 12h16M4 18h16"
              }
            ></path>
          </svg>
        </button>
        <div className="flex bg-slate-50 cursor-pointer ">
          {/*Aside Links */}
          {links.map((link, index) => (
            <a
              key={index}
              className="flex justify-center items-center p-2 hover:bg-gray-200"
              href={link.url}
            >
              {link.icon}
            </a>
          ))}
          {/*Button log out */}
          <button
            className="p-2 hover:bg-gray-200"
            onClick={() => handleLogout()}
          >
            <img
              src="/icons/logout.svg"
              alt="Log out Icon"
              width={24}
              height={24}
            />
          </button>
        </div>
      </div>
      {/*SideMenu*/}
      <div
        className={` fixed left-0 top-0 w-full h-screen bg-white transform transition-all duration-700 z-20 ${
          isMenuMobileOpen ? "translate-x-0" : "-translate-x-full"
        } `}
      >
        <div className="h-10 border-b">
          <button
            className="absolute top-2 right-2"
            onClick={() => setIsMenuMobileOpen(!isMenuMobileOpen)}
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 384 512"
              fill="#333"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
        </div>
        <ul>
          {links.map((link, index) => (
            <li
              key={index}
              className="flex justify-center items-center p-2 hover:bg-gray-200"
            >
              <a className="" href={link.url}>
                {link.name}
              </a>
            </li>
          ))}
        </ul>
        <div className="flex justify-center items-center p-2">
          <button
            className="items-center text-sm"
            onClick={() => handleLogout()}
          >
            Log out
          </button>
        </div>
      </div>
      {/* Desktop Menu */}
      <div className="hidden relative h-screen md:flex md:sticky md:top-0 md:left-0 ">
        <div className="flex flex-col w-12 bg-slate-50 ">
          {/*Aside links */}
          {links.map((link, index) => (
            <a
              key={index}
              className="flex justify-center p-2 hover:bg-gray-200"
              href={link.url}
            >
              {link.icon}
            </a>
          ))}
          {/*Button log out */}
          <button
            className="p-2 hover:bg-gray-200"
            onClick={() => handleLogout()}
          >
            <img
              src="/icons/logout.svg"
              alt="Log out Icon"
              width={24}
              height={24}
            />
          </button>
        </div>
        <div
          className={`${
            !isMenuDesktopOpen ? "translate-x-0" : "-translate-x-10"
          }`}
        >
          <div
            className={`relative flex-shrink-0 w-[236px] bg-white h-full transform transition-all duration-300 ${
              isMenuDesktopOpen ? "hidden" : null
            }`}
          >
            {/*Links*/}
            <div className="absolute top-0 bottom-0 w-full border">
              <ul className="flex flex-col items-center w-full py-5 text-lightBlack ">
                {links.map((link, index) => (
                  <div
                    className="flex w-full justify-center items-center py-2 hover:border-b-2 hover:border-b-blueLogo hover:bg-blue-200 transition-all ease-linear cursor-pointer"
                    key={index}
                  >
                    <a className="px-2 font-medium " href={link.url}>
                      {link.name}
                    </a>
                  </div>
                ))}
                <div className="flex w-full justify-center items-center py-2 hover:border-b-2 hover:border-b-blueLogo hover:bg-blue-200 transition-all ease-linear cursor-pointer">
                  <button
                    type="button"
                    className="px-2 text-sm"
                    onClick={() => handleLogout()}
                  >
                    Log out
                  </button>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="absolute top-0 bottom-0 left-full w-[14px] ">
          <button className="absolute top-[calc(2rem)] w-7 h-7 flex justify-center items-center transform -translate-y-1/2 -translate-x-1/2 cursor-pointer bg-gray-300  hover:bg-blue-200 rounded-full transition-all ease-in z-50">
            <img
              src="/icons/caret.svg"
              width={24}
              height={24}
              alt="Caret Icon"
              className=" "
              onClick={() => setIsMenuDesktopOpen(!isMenuDesktopOpen)}
            />
          </button>
        </div>
      </div>
    </nav>
  )
}
