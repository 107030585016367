import React from "react"
import { Outlet } from "react-router-dom"
import { Navigate } from "react-router-dom"
export default function ProtectedRoute({ requiredRole, fallback }) {
  const role = JSON.parse(localStorage.getItem("MY_AUTH_APP"))
  if (role.role !== requiredRole) {
    return <Navigate to={fallback} />
  }
  return <Outlet />
}
