/** @format */
export default function FormInput({
  name,
  label,
  register,
  step,
  type,
  required,
  errors,
}) {
  const value = required ? true : false
  return (
    <div className="flex flex-col">
      <label className="text-gray-500 text-sm" htmlFor={label}>
        {name}
      </label>
      <div className="h-12 border rounded bg-white xl:w-64">
        <input
          id={label}
          className=" w-full h-full px-4 outline-none bg-transparent"
          type={type}
          step={step}
          {...register(label, { required: value })}
        />
      </div>
      {errors?.type === "required" && (
        <span className="text-red-700">Este campo es requerido</span>
      )}
    </div>
  )
}
