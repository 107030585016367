/** @format */
import { ToastContainer } from "react-toastify"
import "./App.css"
import Layout from "./pages/Layout"
import Login from "./pages/Login"
import FormNewProperty from "./pages/FormNewProperty"
import FormEditProperty from "./pages/FormEditProperty"
import RealState from "./pages/RealState"
import { AuthProvider } from "./context/auth"
import PrivateRoute from "./components/PrivateRoute"
import ProtectedRoute from "./components/ProtectedRoute"
import FormEditEnterpriseUsers from "./pages/FormEditEnterpriseUsers"
import FormNewEnterpriseUser from "./pages/FormNewEnterpriseUser"
import EnterpriseUsers from "./pages/EnterpriseUsers"

import { BrowserRouter as Router, Routes, Route, Form } from "react-router-dom"
function App() {
  return (
    <div>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/dashboard" element={<PrivateRoute />}>
              <Route element={<Layout />}>
                <Route
                  index
                  path="/dashboard/realState"
                  element={<RealState />}
                ></Route>
                <Route
                  path="/dashboard/enterpriseUsers"
                  element={<EnterpriseUsers />}
                ></Route>
                <Route
                  path="/dashboard/realState/formEditProperty"
                  element={<FormEditProperty />}
                ></Route>
                <Route
                  element={
                    <ProtectedRoute
                      requiredRole={"admin"}
                      fallback={"/dashboard/realState"}
                    />
                  }
                >
                  <Route
                    path="/dashboard/realState/formNewProperty"
                    element={<FormNewProperty />}
                  ></Route>
                </Route>
                <Route
                  path="/dashboard/enterpriseUsers"
                  element={
                    <ProtectedRoute
                      requiredRole={"admin"}
                      fallback={"/dashboard/enterpriseUsers"}
                    />
                  }
                >
                  <Route
                    path="/dashboard/enterpriseUsers/formNewUser"
                    element={<FormNewEnterpriseUser />}
                  ></Route>
                  <Route
                    path="/dashboard/enterpriseUsers/formEditUser"
                    element={<FormEditEnterpriseUsers />}
                  ></Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </Router>
        <ToastContainer />
      </AuthProvider>
    </div>
  )
}

export default App
