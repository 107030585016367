import React from "react"
import { useForm } from "react-hook-form"
import { axiosApi } from "../utils/axiosInstance.js"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import FormInput from "../components/Form/Inputs/FormInput"
export default function FormNewEnterpriseUser() {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const handleNewUser = async (data) => {
    try {
      const response = await axiosApi.post(
        "/dashboard/enterpriseUsers/newUser",
        data
      )
      if (response.status === 201) reset()
      navigate("/dashboard/enterpriseUsers", { replace: true })
      toast.success("User created Successfully")
    } catch (error) {
      const errorMessage =
        error.message === "Network Error"
          ? "Please check your internet connection"
          : error.response?.data || "Something wentwrong"
      toast.error(errorMessage)
    }
  }
  return (
    <main className="mx-10 py-5">
      <h1 className="mt-12 text-center text-3xl font-bold md:text-left">
        CREAR USUARIO
      </h1>
      <form className="space-y-10" onSubmit={handleSubmit(handleNewUser)}>
        <div className="flex flex-col mt-5 w-full xl:justify-between gap-3">
          {/* <div className="flex flex-col gap-2">
            <label className="text-gray-500">Username</label>
            <input
              className="w-64 h-11 flex gap-2.5 px-2 py-2.5 border rounded border-grayBorderNavigationSidebar outline-none"
              type="text"
              id="username"
              placeholder="Username"
              {...register("username", { required: true })}
            />
            {errors.username?.type === "required" && (
              <span className="text-red-700">Este campo es requerido</span>
            )}
          </div> */}
          <FormInput
            name="Nombre de usuario"
            label="username"
            type="text"
            required={true}
            errors={errors}
            register={register}
          />
          <FormInput
            name="Contraseña"
            label="password"
            type="password"
            required={true}
            errors={errors}
            register={register}
          />
          <div className="flex flex-col gap-2">
            <label className="text-gray-500">Role</label>
            <select
              id="Role"
              className="flex gap-2.5 px-2 py-4 border rounded border-grayBorderNavigationSidebar outline-none xl:w-64 xl:h-11"
              {...register("roleName")}
            >
              <option value="admin">Administrador</option>
              <option value="moderator">Moderador</option>
            </select>
          </div>
        </div>
        <button
          className="flex w-36 h-12 justify-center items-center rounded bg-blueLogo text-white hover:bg-opacity-80 transition-opacity duration-300ms ease-in"
          type="submit"
        >
          Add
        </button>
      </form>
    </main>
  )
}
