/** @format */

import { deleteImageSlider } from "../../utils/ImageUtils"
export default function ImageSliderWithDeleteButton({
  imagePublicId,
  index,
  propertyId,
  setImageSliderToUpdate,
  url,
}) {
  return (
    <div className="relative px-1" key={index}>
      <img className="rounded" src={url} alt={index} />
      <button
        className="absolute right-1 top-1"
        formNoValidate={true}
        onClick={(e) =>
          deleteImageSlider({
            e,
            propertyId: propertyId,
            publicId: imagePublicId,
            setImageSliderToUpdate: setImageSliderToUpdate,
          })
        }
      >
        <img
          className="hover:w-7 hover:h-7"
          src="/icons/closeCircle.svg"
          alt="Close Icon"
          width={24}
          height={24}
        />
      </button>
    </div>
  )
}
