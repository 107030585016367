import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { login } from "../utils/Auth.js"
import { toast } from "react-toastify"
import { useAuth } from "../context/auth.js"
import { useNavigate } from "react-router-dom"
export default function Login() {
  const { setIsAuthenticated } = useAuth()
  const navigate = useNavigate()
  const [showPassword, setshowPassword] = useState(false)
  const { register, handleSubmit } = useForm()
  const handleLogin = async (data) => {
    try {
      const { username, password } = data
      const response = await login(username, password)
      if (response.status === 200) {
        window.localStorage.setItem(
          "MY_AUTH_APP",
          JSON.stringify({
            isAuthenticated: true,
            role: response.data.role,
          })
        )
        await setIsAuthenticated({
          isAuthenticated: true,
          role: response.data.role,
        })
        navigate("/dashboard/realState")
        toast.success("Log in successfully")
      }
    } catch (error) {
      console.log(error)
      const errorMessage =
        error.message === "Network Error"
          ? " Please check your connection"
          : error.response?.data || "Something went wrong"
      toast.error(errorMessage)
    }
  }
  return (
    <main className="container flex bg-whiteBgLogin">
      <div className="hidden h-screen bg-opacity-80 lg:flex lg:w-[600px] xl:w-[1024px]   ">
        <img
          className="bg-cover h-screen shadow-md"
          src="https://res.cloudinary.com/dpj4akaog/image/upload/v1691360992/Indoles/IMG_9868_1_tdmkbo.webp"
          alt="Group Indoles"
          loading="lazy"
        />
      </div>
      <div className="relative flex-1 flex h-screen justify-center items-center">
        <form
          className="flex flex-col w-full items-center gap-5"
          onSubmit={handleSubmit(handleLogin)}
        >
          <h1 className="text-center text-4xl font-bold ">Ingresar</h1>
          <div className="relative w-72 h-14 border-2 bg-white border-grayBorder rounded-lg shadow-sm ">
            <img
              className="absolute left-2 top-3"
              src="/icons/user.svg"
              alt="User icon"
              width={24}
              height={24}
            />
            <input
              className="w-full h-full pl-12 pr-2 outline-none rounded-lg bg-transparent"
              placeholder="Username"
              type="text"
              {...register("username", { required: true })}
            />
          </div>
          <div className="relative w-72 h-14 border-2 border-grayBorder rounded-lg  shadow-sm ">
            <img
              className="absolute left-2 top-3"
              src="/icons/lock.svg"
              alt="Lock icon"
              width={24}
              height={24}
            />
            <input
              className="w-full h-full pl-12 pr-2 outline-none rounded-lg bg-transparent"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              {...register("password", { required: true })}
            />
            <button
              className="absolute right-2 top-3"
              type="button"
              onClick={() => setshowPassword(!showPassword)}
            >
              {showPassword ? (
                <img
                  src="/icons/eyeOpen.svg"
                  alt="Eye open icon"
                  width={24}
                  height={24}
                />
              ) : (
                <img
                  src="/icons/eyeClose.svg"
                  alt="Eye close icon"
                  width={24}
                  height={24}
                />
              )}
            </button>
          </div>
          <button
            className="w-72 h-14 rounded-lg bg-blueLogo text-white hover:bg-opacity-80 transition-all ease-in"
            type="submit"
          >
            Log In
          </button>
        </form>
      </div>
    </main>
  )
}
