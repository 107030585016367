import { useState } from "react"
import { axiosApi } from "../utils/axiosInstance.js"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import "react-toastify/dist/ReactToastify.css"
import propertyTypes from "../mocks/propertyTypes.json"
import Features from "../components/Form/Arrays/Features"
import FormInput from "../components/Form/Inputs/FormInput"
export default function FormNewProperty() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const reasetAllfeatures = () => {
    setCharacteristics([])
    setUsages([])
    setAmenities([])
    setCommonZones([])
    setOfTheSector([])
  }
  const [amenities, setAmenities] = useState([])
  const [characteristics, setCharacteristics] = useState([])
  const [commonZones, setCommonZones] = useState([])
  const [ofTheSector, setOfTheSector] = useState([])
  const [usages, setUsages] = useState([])
  const handleSubmitFormNewProperty = async (data) => {
    try {
      const formData = new FormData()
      const {
        amountAdmin,
        auxiliaryDiningRoom,
        americanStyleBar,
        americanStyleKitchen,
        antiquity,
        bedroomHalls,
        bedrooms,
        bibliotecary,
        builtArea,
        chimney,
        city,
        closet,
        clothingRoom,
        cornerBack,
        coveredParking,
        courtyardOfClothes,
        description,
        deposit,
        diningRoom,
        eastView,
        floor,
        hall,
        integralKitchen,
        internalGarden,
        kitchen,
        laundryRoom,
        levels,
        mainBathroom,
        marbledFloor,
        neighborhood,
        price,
        pricePerMeter,
        privateArea,
        roomBathroom,
        serviceRoom,
        socialBathroom,
        otherTypeOfFloor,
        stairs,
        serviceBathrooms,
        scortRoom,
        state,
        stratus,
        street,
        studyRoom,
        terrace,
        tiledFloor,
        tvRoom,
        typeOfProperty,
        visitorParking,
        uncoveredParking,
        wathsherAndDryer,
        westView,
        woodenFloor,
      } = data
      formData.append("imageCard", data.imageCard[0])
      for (const image of data.imagesSlider) {
        formData.append("imagesSlider", image)
      }
      const property = {
        propertyType: typeOfProperty,
        address: {
          street,
          neighborhood,
          city,
          state,
        },
        amenities,
        characteristics,
        commonZones,
        components: {
          auxiliaryDiningRoom: parseInt(auxiliaryDiningRoom) || 0,
          americanStyleKitchen: parseInt(americanStyleKitchen) || 0,
          americanStyleBar: parseInt(americanStyleBar) || 0,
          bedroomHalls: parseInt(bedroomHalls) || 0,
          bedrooms: parseInt(bedrooms) || 0,
          bibliotecary: parseInt(bibliotecary) || 0,
          chimney: parseInt(chimney) || 0,
          closet: parseInt(closet) || 0,
          clothingRoom: parseInt(clothingRoom) || 0,
          courtyardOfClothes: parseInt(courtyardOfClothes) || 0,
          coveredParking: parseInt(coveredParking) || 0,
          deposit: parseInt(deposit) || 0,
          diningRoom: parseInt(diningRoom) || 0,
          hall: parseInt(hall) || 0,
          integralKitchen: parseInt(integralKitchen) || 0,
          internalGarden: parseInt(internalGarden) || 0,
          kitchen: parseInt(kitchen) || 0,
          laundryRoom: parseInt(laundryRoom) || 0,
          mainBathroom: parseInt(mainBathroom) || 0,
          marbledFloor: parseInt(marbledFloor) || 0,
          otherTypeOfFloor,
          roomBathroom: parseInt(roomBathroom) || 0,
          serviceRoom: parseInt(serviceRoom) || 0,
          serviceBathrooms: parseInt(serviceBathrooms) || 0,
          socialBathroom: parseInt(socialBathroom) || 0,
          studyRoom: parseInt(studyRoom) || 0,
          stairs: parseInt(stairs) || 0,
          scortRoom: parseInt(scortRoom) || 0,
          tiledFloor: parseInt(tiledFloor) || 0,
          terrace: parseInt(terrace) || 0,
          tvRoom: parseInt(tvRoom) || 0,
          visitorParking: parseInt(visitorParking) || 0,
          uncoveredParking: parseInt(uncoveredParking) || 0,
          washerAndDryer: parseInt(wathsherAndDryer) || 0,
          woodenFloor: parseInt(woodenFloor) || 0,
        },
        data: {
          amountAdmin: parseFloat(amountAdmin),
          antiquity: parseInt(antiquity),
          builtArea: parseFloat(builtArea),
          cornerBack: cornerBack === "true" ? true : false,
          eastView: eastView === "true" ? true : false,
          floor: parseInt(floor),
          levels: parseInt(levels),
          price: parseInt(price),
          pricePerMeter: parseFloat(pricePerMeter),
          privateArea: parseFloat(privateArea),
          stratus: parseInt(stratus),
          westView: westView === "true" ? true : false,
        },
        description,
        media: {
          imageCard: {
            url: "",
            publicId: "",
          },
          imageSlider: [],
          video: {
            url: "",
            publicId: "",
          },
        },
        ofTheSector,
        usage: usages,
      }
      formData.append("property", JSON.stringify(property))
      formData.append("video", data.video[0])
      const response = await axiosApi.post("/realState", formData)
      reset()
      reasetAllfeatures()
      toast(response.data)
    } catch (error) {
      console.log(error.message)
      toast(error.message)
    }
  }
  return (
    <main className="mx-10 py-5">
      <h1 className="mt-12 text-center text-3xl font-bold md:text-left">
        NUEVO INMUEBLE
      </h1>
      <Features
        label="Características"
        featuresToUpdate={characteristics}
        setFeaturesToUpdate={setCharacteristics}
      />
      <Features
        label="Tipo de negocio"
        featuresToUpdate={usages}
        setFeaturesToUpdate={setUsages}
      />
      <Features
        label="Amenidades"
        featuresToUpdate={amenities}
        setFeaturesToUpdate={setAmenities}
      />
      <Features
        label="Zonas Comunes"
        featuresToUpdate={commonZones}
        setFeaturesToUpdate={setCommonZones}
      />
      <Features
        label="Del Sector"
        featuresToUpdate={ofTheSector}
        setFeaturesToUpdate={setOfTheSector}
      />
      <form
        className="space-y-10"
        onSubmit={handleSubmit(handleSubmitFormNewProperty)}
      >
        <div className="grid gap-5 mx-10 my-10">
          <div className="flex flex-col gap-2.5">
            <h2 className="font-medium text-lg">Video</h2>
            <input type="file" accept="video/*" {...register("video")} />
          </div>
          <div className="flex flex-col gap-2.5">
            <h2 className="font-medium text-lg">Imagen Fachada</h2>
            <input
              type="file"
              accept="image/*"
              {...register("imageCard", { required: true })}
            />
            {errors.imageCard?.type === "required" && (
              <span className="text-red-700">Debes cargar la imagen</span>
            )}
          </div>
          <div className="flex flex-col gap-2.5">
            <h2 className="font-medium text-lg">Imagenes Slider</h2>
            <input
              type="file"
              multiple
              accept="image/*"
              {...register("imagesSlider", { required: true })}
            />
            {errors.imagesSlider?.type === "required" && (
              <span className="text-red-700">Debes cargar las imagenes</span>
            )}
          </div>
        </div>
        <div className="grid xl:grid-cols-2 gap-2.5 ">
          <div className="flex flex-col gap-2">
            <h2 className="font-normal text-lg">Tipo de Propiedad</h2>
            <select
              id="typeOfProperty"
              className="xl:w-64 xl:h-11 flex gap-2.5 px-2 py-2.5 border rounded border-grayBorderNavigationSidebar"
              {...register("typeOfProperty", { required: true })}
            >
              {propertyTypes.map((type) => (
                <option key={type.id} value={type.value}>
                  {type.value}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-2.5">
            <h2 className="font-medium text-lg">Descripción</h2>
            <textarea
              className="px-4 py-2 border rounded border-grayTextConsultory outline-none"
              {...register("description")}
            ></textarea>
          </div>
        </div>
        {/* Data property */}
        <div className="grid gap-5 xl:grid-cols-2">
          <div className="flex flex-col items-center gap-2.5">
            <h2 className="font-normal text-lg">Datos del Inmueble</h2>
            <div className="grid w-full xl:grid-cols-2 gap-10">
              <FormInput
                label="stratus"
                errors={errors.stratus}
                name="Estrato"
                type="number"
                register={register}
                required={true}
                step=""
              />

              <FormInput
                errors={errors.antiquity}
                label="antiquity"
                name="Antiguedad"
                type="number"
                register={register}
                required={true}
                step=""
              />
              <FormInput
                errors={errors.pricePerMeter}
                label="pricePerMeter"
                name="Precio por metro cuadrado"
                type="number"
                register={register}
                required={true}
                step="any"
              />

              <FormInput
                errors={errors.floor}
                label="floor"
                name="Piso"
                type="number"
                register={register}
                required={true}
                step=""
              />
              <FormInput
                errors={errors.builtArea}
                label="builtArea"
                name="Area construida"
                type="number"
                register={register}
                required={true}
                step="any"
              />
              <FormInput
                label="privateArea"
                errors={errors.privateArea}
                name={"Area privada"}
                type="number"
                register={register}
                required={true}
                step="any"
              />
              <FormInput
                errors={errors.price}
                label="price"
                name={"Precio de venta"}
                type="number"
                register={register}
                required={true}
                step=""
              />
              <FormInput
                errors={errors.levels}
                label="levels"
                name={"Niveles"}
                type="number"
                register={register}
                required={true}
                step=""
              />
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm" htmlFor="cornerBack">
                  Esquinero
                </label>
                <select
                  id="cornerBack"
                  className="xl:w-64 xl:h-11 flex gap-2.5 px-2 py-2.5 border rounded border-grayBorderNavigationSidebar"
                  {...register("cornerBack")}
                >
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm" htmlFor="eastView">
                  Vista al oriente
                </label>
                <select
                  id="eastView"
                  className="xl:w-64 xl:h-11 flex gap-2.5 px-2 py-2.5 border rounded border-grayBorderNavigationSidebar"
                  {...register("eastView")}
                >
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-sm" htmlFor="westView">
                  Vista al occidente
                </label>
                <select
                  id="westView"
                  className="xl:w-64 xl:h-11 flex gap-2.5 px-2 py-2.5 border rounded border-grayBorderNavigationSidebar"
                  {...register("westView")}
                >
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </select>
              </div>

              <FormInput
                errors={errors.amountAdmin}
                label="amountAdmin"
                name={"Cuota de administración"}
                type="number"
                register={register}
                required={false}
                step="any"
              />
            </div>
          </div>
          {/*  Address */}
          <div className="flex flex-col items-center gap-2.5">
            <h2 className="font-normal text-lg ">Dirección</h2>
            <div className="flex flex-col w-full gap-5 xl:items-center">
              <FormInput
                errors={errors.street}
                label="street"
                name={"Calle"}
                type="text"
                register={register}
                required={true}
              />
              <FormInput
                errors={errors.neighborhood}
                label="neighborhood"
                name={"Barrio"}
                type="text"
                register={register}
                required={true}
              />
              <FormInput
                errors={errors.city}
                label="city"
                name={"Ciudad"}
                type="text"
                register={register}
                required={true}
              />
              <FormInput
                errors={errors.state}
                label="state"
                name={"Departamento"}
                type="text"
                register={register}
                required={true}
              />
            </div>
          </div>
        </div>
        {/* Components */}
        <div className="flex flex-col items-center gap-2.5">
          <h2 className="font-normal text-lg">Componentes del inmueble</h2>
          <div className="grid w-full xl:grid-cols-4 gap-10">
            <FormInput
              label="auxiliaryDiningRoom"
              name="Comedor auxiliar"
              type="number"
              register={register}
            />
            <FormInput
              label="americanStyleKitchen"
              name="Cocina tipo americano"
              type="number"
              register={register}
            />
            <FormInput
              label="americanStyleBar"
              name="Barra estilo americano"
              type="number"
              register={register}
            />
            <FormInput
              label="bedrooms"
              name="Habitaciones"
              type="number"
              register={register}
            />
            <FormInput
              label="bedroomHalls"
              name="Hall de alcobas"
              type="number"
              register={register}
            />
            <FormInput
              label="bibliotecary"
              name="Biblioteca"
              type="number"
              register={register}
            />
            <FormInput
              label="closet"
              name="Closet"
              type="number"
              register={register}
            />
            <FormInput
              label="clothingRoom"
              name="Clothing Room"
              type="number"
              register={register}
            />
            <FormInput
              label="chimney"
              name="Chimenea"
              type="number"
              register={register}
            />
            <FormInput
              label="coveredParking"
              name="Parqueadero cubierto"
              type="number"
              register={register}
            />
            <FormInput
              label="courtyardOfClothes"
              name="Patio de ropas"
              type="number"
              register={register}
            />
            <FormInput
              label="deposit"
              name="Depósito"
              type="number"
              register={register}
            />
            <FormInput
              label="hall"
              name="Hall"
              type="number"
              register={register}
            />
            <FormInput
              label="internalGarden"
              name="Jardín interno"
              type="number"
              register={register}
            />
            <FormInput
              label="integralKitchen"
              name="Cocina integral"
              type="number"
              register={register}
            />
            <FormInput
              label="kitchen"
              name="Cocina"
              type="number"
              register={register}
            />
            <FormInput
              label="laundryRoom"
              name="Cuarto de lavandería"
              type="number"
              register={register}
            />
            <FormInput
              label="mainBathroom"
              name="Baño principal"
              type="number"
              register={register}
            />
            <FormInput
              label="marbledFloor"
              name="Piso en mármol"
              type="number"
              register={register}
            />
            <FormInput
              label="otherTypeOfFloor"
              name="Otro tipo de piso"
              type="text"
              register={register}
            />
            <FormInput
              label="uconveredParking"
              name="Parqueadero descubierto"
              type="number"
              register={register}
            />
            <FormInput
              label="roomBathroom"
              name="Baño de habitaciónes"
              type="number"
              register={register}
            />
            <FormInput
              label="terrace"
              name="Terraza"
              type="number"
              register={register}
            />
            <FormInput
              label="tvRoom"
              name="Sala de tv"
              type="number"
              register={register}
            />
            <FormInput
              label="tiledFloor"
              name="Piso en baldosa"
              type="number"
              register={register}
            />
            <FormInput
              label="scortRoom"
              name="Sala de escoltas"
              type="number"
              register={register}
            />
            <FormInput
              label="serviceRoom"
              name="Cuarto de servicio"
              type="number"
              register={register}
            />
            <FormInput
              label="serviceBathrooms"
              name="Baños de servicio"
              type="number"
              register={register}
            />
            <FormInput
              label="stairs"
              name="Escaleras"
              type="number"
              register={register}
            />
            <FormInput
              label="studyRoom"
              name="Estudio"
              type="number"
              register={register}
            />
            <FormInput
              label="socialBathroom"
              name="Baño social"
              type="number"
              register={register}
            />
            <FormInput
              label="visitorParking"
              name="Parqueadero para visitantes"
              type="number"
              register={register}
            />
            <FormInput
              label="washerAndDryer"
              name="Lavadora y secadora"
              type="number"
              register={register}
            />
            <FormInput
              label="woodenFloor"
              name="Pisos en madera"
              type="number"
              register={register}
            />
          </div>
        </div>
        <div className="flex justify-center ">
          <button
            className="px-4 w-full h-11 mb-10 lg:mx-2 bg-blueLogo rounded-[6px] text-white hover:bg-opacity-90"
            type="submit"
          >
            Add
          </button>
        </div>
      </form>
    </main>
  )
}
