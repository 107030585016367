import React from "react"
import { axiosApi } from "../utils/axiosInstance.js"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
export default function useGetEnterpriseUserData(id) {
  const [userData, setUserData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getUserData = async (id) => {
      try {
        const response = await axiosApi.get(
          `/dashboard/enterpriseUser/userDetails?id=${id}`
        )
        if (response.status === 200) {
          setUserData(response.data)
        }
      } catch (error) {
        console.log(error)
        const errorMessage =
          error.message === "Network Error"
            ? "Please check your connection"
            : error.response.data
        toast.error(errorMessage)
      } finally {
        setLoading(false)
      }
    }
    getUserData(id)
  }, [id])
  return { userData, loading }
}
