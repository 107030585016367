import { axiosApi } from "../utils/axiosInstance.js"
import { confirmAlert } from "react-confirm-alert"
import { toast } from "react-toastify"
import "react-confirm-alert/src/react-confirm-alert.css"
export const useDeleteEnterpriseUser = () => {
  const deleteEnterpriseUser = async (id) => {
    confirmAlert({
      title: "Delete user",
      message: "Are you sure you want to delete the User?",
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              const response = await axiosApi.delete(
                `/dashboard/enterpriseUsers?id=${id}`
              )
              if (response.status === 200) {
                toast.success(response.data)
              }
            } catch (error) {
              console.log(error)
              const errorMessage =
                error.message === "Network Error"
                  ? "Network Error"
                  : error.response.data || "Something went wrong"
              toast.error(errorMessage)
            }
          },
        },
        {
          label: "No",
          onClick: () => {
            // User clicked No or closed the dialog
          },
        },
      ],
    })
  }

  return { deleteEnterpriseUser }
}
